/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import TitleDefault from 'components/elements/TitleDefault'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      path,
      title,
      yoastMeta
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <div className="container py-5">
        <TitleDefault>{title}</TitleDefault>
      </div>
    </Layout>
  )
}

export default PageTemplate
